div.SearchBar {
  height: 72px;
  border-bottom: 1px solid var(--mid);

  display: flex;
  place-items: center;
  overflow: hidden;

  width: 100%;
  height: 72px;
  padding: 12px 12px;
  background-color: var(--light);

  will-change: height, padding;
  transition: height 500ms, padding 500ms;

  input {
    flex-grow: 1;
    height: 100%;
    font-size: 18px;
    border: 1px solid var(--mid);
    border-radius: 24px;
    outline: none;
    padding: 0 16px;
    min-width: 0;

    background-color: var(--lightest);
    font-family: Inter;

    box-shadow: none;
    transition: box-shadow 250ms;
    &:focus { box-shadow: var(--shadow-light); }

    &.incorrect {
      border: 2px solid rgba(255, 0, 0, 0.5);
    }
  }

  i {
    font-size: 28px;
    margin: 0 8px 0 16px;
    color: var(--accent);
    opacity: 0.4;
    transition: opacity 100ms;
    &:hover { opacity: 0.6; }

    cursor: pointer;
    user-select: none;
  }

  &.hidden {
    height: 0px;
    padding: 0px 12px;
    border-bottom: none;
  }
}