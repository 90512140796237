div.ConversationHeader {
  grid-row: 1;
  grid-column: 2;

  background-color: var(--light);
  border-left: 1px solid var(--mid);
  border-bottom: 1px solid var(--mid);

  padding: 16px;
  display: flex;
  place-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    animation: fade-in 750ms ease;
  }

  span {
    flex: 1;
    margin-left: 16px;
    font-size: 16px;
    animation: fade-in 750ms ease;
  }

  i:first-child {
    margin-right: 8px;
    animation: fade-in 750ms ease;
  }
}