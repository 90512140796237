div.Chats {
  grid-row: 2;
  grid-column: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
    
  &::-webkit-scrollbar-track { 
    background-color: var(--light);
    border-radius: 0px;
  }
    
  &::-webkit-scrollbar-thumb {
    background-color: var(--mid);
    border-radius: 0px;

    &:hover { background-color: var(--dark); }
    &:active { background-color: var(--darker); }
  }

  div.ChatPreview {
    position: relative;
    height: 92px;
    padding: 16px;
    width: 100%;
    border-bottom: 1px solid var(--light);
    background-color: var(--lightest);
    transition: background-color 150ms, border-bottom 150ms;
    cursor: pointer;
    
    &:hover, &.active { 
      background-color: var(--light);
      border-bottom: 1px solid var(--mid);
    }

    display: grid;
    grid-template-columns: 60px 1fr auto;
    grid-template-rows: 32px 1fr;
    row-gap: 2px;

    user-select: none;
    -webkit-tap-highlight-color: transparent;

    img {
      height: 60px;
      width: 60px;
      border-radius: 50%;

      grid-row: 1 / 3;
      grid-column: 1;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span.unreadBubble {
      position: absolute;
      top: 54px;
      left: 54px;

      display: block;
      width: 24px;
      height: 24px;
      border-radius: 12px;

      background-color: var(--accent-light);
      box-shadow: var(--shadow-light);
    }

    span.recipientName {
      grid-row: 1;
      grid-column: 2;
      font-size: 22px;
      font-weight: 200;
      align-self: end;
      margin-left: 16px;

      &.unread {
        font-weight: 400;
      }
    }

    span.messageDate {
      grid-row: 1;
      grid-column: 3;
      align-self: end;
      margin-bottom: 2.7px;
      opacity: 0.5;
      font-size: 12px;
    }

    span.latestMessage {
      grid-row: 2;
      grid-column: 2 / 4;
      margin-left: 16px;
      opacity: 0.75;
    }
  }

  &.empty {
    font-weight: 200;
    font-size: 18px;
    text-align: center;

    div.SearchBar {
      margin-bottom: 32px;
    }
  }
}