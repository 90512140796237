div.Conversation {
  grid-row: 2;
  grid-column: 2;
  border-left: 1px solid var(--mid);

  display: flex;
  flex-direction: column;
  overflow: hidden;

  div.messages {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;

    div.Message {
      margin: 20px;
      text-align: right;
      
      div.messageContent {
        display: inline-block;
        background-color: var(--accent-light);
        border-radius: 15px 15px 0 15px;
        padding: 10px;
        max-width: calc(100% - 80px);
        word-break: break-word;
        animation: pop-up 500ms ease;
      }

      div.messageTime {
        display: inline-block;
        margin: 0 4px;
        vertical-align: bottom;
        opacity: 0.8;
        font-weight: 200;
      }

      div.typingDot {
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 4px 4px 1px 4px;
        opacity: 0.5;
        border-radius: 100%;
        border: 2px solid var(--accent);
        animation-name: bounce;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        transform: translateY(6px);
      }

      i {
        cursor: unset;
        vertical-align: bottom;
        display: inline-block;
        margin: 0 4px -4px 0;
        line-height: 100%;
        &:hover { opacity: 0.4 !important; }

        &.bi-check { color: var(--text); }
      }

      &.senderRemote {
        text-align: left;

        div.messageContent {
          border-radius: 15px 15px 15px 0;
          background-color: var(--light);
        }
      }
    }
  }

  div.messageInput {
    display: flex;
    place-items: center;

    width: 100%;
    height: 72px;
    padding: 12px;
    background-color: var(--light);
    border-top: 1px solid var(--mid);

    animation: fade-in 500ms ease;

    input {
      flex: 1;
      height: 100%;
      font-size: 18px;
      border: 1px solid var(--mid);
      border-radius: 24px;
      outline: none;
      padding: 0 16px;
      min-width: 0;

      background-color: var(--lightest);
      font-family: Inter;

      box-shadow: none;
      transition: box-shadow 250ms;
      &:focus { box-shadow: var(--shadow-light); }
    }

    i {
      margin: 0 8px 4px 16px;
    }
  }

  &.empty {
    place-items: center;
    place-content: center;
    font-size: 24px;
    font-weight: 200;
    text-align: center;
  }
}

@keyframes bounce {
  0% { transform: translateY(6px); }
  50% { transform: translateY(-6px); }
  100% { transform: translateY(6px); }
}