div.Menu {
  grid-row: 1;
  grid-column: 1;
  padding: 16px;
  overflow: hidden;
  display: flex;
  place-items: center;
  background-color: var(--light);
  border-bottom: 1px solid var(--mid);

  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }

  div {
    width: 100%;
    text-align: right;
    
    i {
      font-size: 28px !important;
      color: var(--accent);
      opacity: 0.4;
      transition: opacity 100ms;
      &:hover { opacity: 0.6; }

      cursor: pointer;
      user-select: none;
      
      margin-left: 24px;
      vertical-align: middle;
    }
  }
}