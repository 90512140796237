div.Landing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 16px;

  nav {
    display: flex;
    place-items: center;
    width: 100%;
    height: 72px;
    padding: 16px;
    background-color: var(--accent-mid);
    box-shadow: var(--shadow-heavy);
    color: white;
    text-shadow: var(--shadow-light);
    font-size: 1.5em;
    font-weight: 800;

    img {
      height: 100%;
      filter: brightness(400%) drop-shadow(var(--shadow-light));
      margin-right: 20px;
    }

    div {
      margin-left: auto;

      div {
        display: inline-flex;
        place-items: center;
        place-content: center;
        width: 48px;
        height: 48px;
        margin-left: 16px;
        color: var(--accent);
        background-color: white;
        border-radius: 4px;
        box-shadow: var(--shadow-wide);
        transition: box-shadow 250ms;

        &:hover {
          box-shadow: var(--shadow-light);
        }
      }
    }
  }

  section.hero {
    color: white;
    min-height: calc(100vh - 72px);
    width: 100%;
    padding: 5vh 5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 6vh;
    background: linear-gradient(to top left, var(--accent-light), var(--accent));

    div.heroContent {
      display: flex;
      flex-direction: column;
      flex: 3;
      place-items: center;
      place-content: center;
      text-align: center;
      font-size: 3vh;
      font-weight: 200;
      min-width: unquote("min(50vh, 100%)");
      text-shadow: var(--shadow-heavy);

      h1 {
        margin: 0 0 1.5vh;
        line-height: 8vh;
        font-size: 7vh;
        color: white;
      }

      div.subtitle {
        font-size: 4vh;
        font-weight: 400;
        margin: 0 0 2vh;
      }

      div.overview {
        min-width: 300px;
      }

      div.buttons {
        margin: 4vh 0 0;

        button {
          margin-left: 16px;
          color: #8ba0db;
          padding: 8px 12px;
          font-size: 0.8em;
          font-weight: 800;
          font-family: Inter;
          background-color: white;
          border-radius: 4px;
          box-shadow: var(--shadow-wide);
          cursor: pointer;
          border: 2px solid white;
          transition: background-color 250ms, color 250ms, box-shadow 250ms;

          &:hover {
            background-color: white;
            color: var(--accent);
            box-shadow: var(--shadow-light);
          }
        }
      }
    }

    div.heroImage {
      display: flex;
      flex: 2;
      place-items: center;
      place-content: center;
      min-width: unquote("min(50vh, 100%)");

      img {
        height: 75vh;
        animation: imageIn 1.5s ease;
        filter: drop-shadow(var(--shadow-heavy));
      }
    }
  }

  section:not(.hero) {
    width: 100%;
    color: var(--text-color);
    background-color: var(--lightest);
    padding: 128px 20vw;
    font-size: 1.2em;

    h1 {
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 1.8em;
      color: var(--accent);
      text-shadow: var(--shadow-wide);
    }

    &:nth-of-type(2n-1) {
      background-color: var(--light);
    }
  }

  ul {
    list-style: none; /* Remove default bullets */

    li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: var(--accent); /* Change the color */
      font-weight: 800; /* If you want it to be bold */
      transform: scale(1.5);
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }
}

@media only screen and (max-width: 600px) {
  div.Landing {
    font-size: 3.5vw;

    nav div div {
      width: 11vw;
      height: 11vw;
    }

    section:not(.hero) {
      padding: 64px;
    }
  }
}

@keyframes imageIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  20% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}